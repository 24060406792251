var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("small", [
    _vm._v(
      _vm._s(
        this.$tc("lbl_characters-x", _vm.value.length, {
          data: _vm.value.length
        })
      )
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }